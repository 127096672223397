import loadable from "@loadable/component";
import { inject } from "mobx-react";
import * as React from "react";

import { ResponsiveImage } from "../components/ResponsiveImage.component";
import type { ImageAssetInterface } from "@ihr-radioedit/inferno-core";
import { getMediaServerUrl, AssetLegacyProps } from "@ihr-radioedit/inferno-core";
import { iHeartDefaultImg, Sizes, SrcSet } from "@inferno/renderer-shared-core";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import "./Asset.style.scss";
import { RESPONSIVE_IMAGE } from "../ui/constants";

const DocumentAsset = loadable(() => import("./DocumentAsset.component"));
const ImageAsset = loadable(() => import("./ImageAsset.component"));
const MediaAsset = loadable(() => import("./MediaAsset.component"));

export interface AssetLegacyState {
  visible: boolean;
}

@inject("store")
export class AssetLegacy extends React.Component<AssetLegacyProps, AssetLegacyState> {
  private observer?: IntersectionObserver;
  private elementRef: React.RefObject<HTMLElement>;
  private imageHost: string;
  mounted = false;

  constructor(props: AssetLegacyProps) {
    super(props);
    this.state = {
      visible: false,
    };

    this.imageHost = this.props?.store?.env ? this.props?.store.env.IMAGE_HOST || "" : "";
    this.elementRef = React.createRef();
  }

  private handleIntersect = (entries: IntersectionObserverEntry[]) => {
    const [item] = entries;
    if (item && item.intersectionRatio > 0.5 && this.mounted) {
      this.setState({
        visible: true,
      });
      if (this.observer) {
        this.observer.disconnect();
      }
    }
  };

  componentDidMount() {
    this.mounted = true;
    if (!this.elementRef.current) {
      return;
    }
    const options = {
      rootMargin: "0px 0px 0px 0px",
      threshold: [0, 0.25, 0.5, 0.75, 1],
    };

    this.observer = new IntersectionObserver(e => this.handleIntersect(e), options);
    this.observer.observe(this.elementRef.current);
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  getBlock = () => {
    if (!this.props.attributes) {
      return null;
    }

    switch (this.props.attributes.type) {
      case "image":
        const imageProps: ImageAssetInterface = {
          url: this.props.url,
          title: this.props.title,
          imgUrl: getMediaServerUrl(this.imageHost, this.props.bucket, this.props.id),
          new_tab: this.props.new_tab,
          caption: this.props.caption,
          alt_text: this.props.alt_text,
          source_credit: this.props.source_credit,
          counter: this.props.counter,
          use_caption: this.props.use_caption,
        };
        return <ImageAsset {...imageProps} />;
      case "document":
        return <DocumentAsset imageHost={this.imageHost} asset={this.props} />;
      case "video":
      case "audio":
        return <MediaAsset {...this.props} />;
      default:
        return <div className="no-content" />;
    }
  };

  getPlaceholder = () => {
    if (!this.props.attributes) {
      return null;
    }

    let imgUrl = "";
    switch (this.props.attributes.type) {
      case "image":
        imgUrl = placeholderImage;
        break;
      case "video":
        imgUrl = iHeartDefaultImg;
        break;
      case "audio":
        imgUrl = iHeartDefaultImg;
        break;
      case "document":
        imgUrl = iHeartDefaultImg;
        break;
    }
    const srcset: SrcSet[] = [
      {
        url: `${imgUrl}?ops=max(360,0),quality(10),blur(50)`,
        descriptor: "360w",
      },
      {
        url: `${imgUrl}?ops=max(480,0),quality(10),blur(50)`,
        descriptor: "480w",
      },
      {
        url: `${imgUrl}?ops=max(650,0),quality(10),blur(50)`,
        descriptor: "650w",
      },
      {
        url: `${imgUrl}?ops=max(1060,0),quality(10),blur(50)`,
        descriptor: "940w",
      },
    ];
    const placeholderSrcset: SrcSet[] = [
      {
        url: `${placeholderImage}?ops=max(360,0),quality(10),blur(50)`,
        descriptor: "360w",
      },
      {
        url: `${placeholderImage}?ops=max(480,0),quality(10),blur(50)`,
        descriptor: "480w",
      },
      {
        url: `${placeholderImage}?ops=max(650,0),quality(10),blur(50)`,
        descriptor: "650w",
      },
      {
        url: `${placeholderImage}?ops=max(1060,0),quality(10),blur(50)`,
        descriptor: "940w",
      },
    ];
    const sizes: Sizes[] = [
      {
        media: "(max-width: 359px)",
        size: "360px",
      },
      {
        media: "(max-width: 479px)",
        size: "480px",
      },
      {
        media: "(max-width: 649px)",
        size: "650px",
      },
      {
        media: "(min-width: 1060px)",
        size: "940px",
      },
      {
        media: "auto",
        size: "",
      },
    ];
    const alt = this.props.title || "placeholder image";
    return (
      <figure
        ref={this.elementRef}
        className="component-asset-placeholder"
        data-placeholder-type={this.props.attributes.type}
      >
        <section className="image-container">
          <ResponsiveImage
            src={placeholderImage}
            srcset={srcset}
            sizes={sizes}
            alt={alt}
            placeholderSrcset={placeholderSrcset}
            initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
            initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
          />
        </section>
      </figure>
    );
  };

  render() {
    const { attributes } = this.props;
    const validAsset = attributes && (attributes.type === "image" || attributes.type === "document");
    if (this.state.visible || validAsset) {
      return this.getBlock();
    } else {
      return this.getPlaceholder();
    }
  }
}

export default AssetLegacy;
